import { IApplicationSubmission, IPolicy } from "../../db/models"
import fetchPartnerDashboardService from "../../service/fetchPartnerDashboardService"

/**
 * @deprecated Use getApplicationSubmissionsForPartner instead.
 *
 * Fetches application submissions with a specific attribute.
 *
 * @param {string} startDate - The start date for the submissions
 * @param {string} endDate - The end date for the submissions
 * @param {string} status - The status to filter by
 * @returns {Promise<IApplicationSubmission[]>} Array of application submissions
 */

export async function getApplicationSubmissionsWithAttribute(
  startDate: string,
  endDate: string,
  status: string
) {
  const res = await fetchPartnerDashboardService(
    "POST",
    {
      startDate,
      endDate,
      status,
    },
    "general/getApplicationSubmissionsWithAttribute"
  )
  return res.applicationSubmissions as IApplicationSubmission[]
}

/**
 * Fetches application submissions for a partner.
 *
 * @param {string} licenseId - The ID of the license.
 * @param {string} startDate - The start date for the submissions.
 * @param {string} endDate - The end date for the submissions.
 * @returns {Promise<ApplicationSubmission[]>} The response data.
 */
export async function getApplicationSubmissionsForPartner(
  licenseId: string,
  startDate: string,
  endDate: string
): Promise<Record<string, IApplicationSubmission[]>> {
  const res = await fetchPartnerDashboardService(
    "POST",
    {
      licenseId,
      startDate,
      endDate,
    },
    "general/getApplicationSubmissionsForPartner"
  )
  return res.applicationSubmissions as Record<string, IApplicationSubmission[]>
}

/**
 * Fetches businesses with application submissions with a specific attribute.
 *
 * @param {string} licenseId - The ID of the license.
 * @param {string} startDate - The start date for the submissions.
 * @param {string} endDate - The end date for the submissions.
 * @param {string} status - The status to filter by.
 * @returns {Promise<number>} The number of businesses with application submissions.
 */
export async function getBusinessesWithApplicationSubmissionsWithAttribute(
  licenseId: string,
  startDate: string,
  endDate: string,
  status: string
): Promise<number> {
  const res = await fetchPartnerDashboardService(
    "POST",
    {
      licenseId,
      startDate,
      endDate,
      status,
    },
    "general/getBusinessesWithApplicationSubmissionsWithAttribute"
  )
  return res.businessesWithApplicationSubmissions as number
}

/**
 * Fetches the total premium for a given date range and license.
 *
 * @param {string} startDate - The start date for the premium.
 * @param {string} endDate - The end date for the premium.
 * @param {string} licenseId - The ID of the license.
 * @returns {Promise<number>} The total premium for the given date range and license.
 */
export async function getTotalPremiumForDates(
  startDate: string,
  endDate: string,
  licenseId: string
): Promise<number> {
  const res = await fetchPartnerDashboardService(
    "POST",
    {
      startDate,
      endDate,
      licenseId,
    },
    "general/getTotalPremiumForDates"
  )
  return res.totalPremium as number
}

/**
 * Fetches policies for a given license and date range.
 *
 * @param {string} licenseId - The ID of the license.
 * @param {string} startDate - The start date for the policies.
 * @param {string} endDate - The end date for the policies.
 * @returns {Promise<{policies: IPolicy[], endorsements: { [license: string]: number }>}> The policies and endorsements for the given license and date range.
 */
export async function getPoliciesForPartner(
  licenseId: string,
  startDate: string,
  endDate: string
) {
  const res = await fetchPartnerDashboardService(
    "POST",
    {
      licenseId,
      startDate,
      endDate,
    },
    "general/getPoliciesForPartner"
  )
  return res as {
    policies: IPolicy[]
    endorsements: { [license: string]: number }
  }
}

/**
 * Fetches the total partner rev share for a given license and date range.
 *
 * @param {string} licenseId - The ID of the license.
 * @param {string} startDate - The start date for the rev share.
 * @param {string} endDate - The end date for the rev share.
 * @returns {Promise<{totalPartnerRevShare: number, [date: string]: number}>} The total partner rev share for the given license and date range.
 */
export async function getTotalPartnerRevShare(
  licenseId: string,
  startDate: string,
  endDate: string
): Promise<{
  totalPartnerRevShare: number
  [date: string]: number
}> {
  const res = await fetchPartnerDashboardService(
    "POST",
    {
      licenseId,
      startDate,
      endDate,
    },
    "general/getTotalPartnerRevShare"
  )
  return res.totalPartnerRevShare as {
    totalPartnerRevShare: number
    [date: string]: number
  }
}

/**
 * Fetches businesses with incomplete applications and their counts by industry filter1.
 *
 * @param {string} licenseId - The ID of the license.
 * @param {string} startDate - The start date for the query.
 * @param {string} endDate - The end date for the query.
 * @returns {Promise<{total: number, filter1s: Array<{name: string, total: number}>}>}
 */
export async function getBusinessesWithIncompleteApplications(
  licenseId: string,
  startDate: string,
  endDate: string
): Promise<{
  total: number
  filter1s: Array<{
    name: string
    total: number
  }>
}> {
  const res = await fetchPartnerDashboardService(
    "POST",
    {
      licenseId,
      startDate,
      endDate,
    },
    "general/getBusinessesWithIncompleteApplications"
  )
  return res.businessesWithIncompleteApplications as {
    total: number
    filter1s: Array<{
      name: string
      total: number
    }>
  }
}

/**
 * Fetches qualified leads and their counts by industry filter1.
 *
 * @param {string} licenseId - The ID of the license.
 * @param {string} startDate - The start date for the query.
 * @param {string} endDate - The end date for the query.
 * @returns {Promise<{total: number, filter1s: Array<{name: string, total: number}>}>}
 */
export async function getQualifiedLeads(
  licenseId: string,
  startDate: string,
  endDate: string
): Promise<{
  total: number
  filter1s: Array<{
    name: string
    total: number
  }>
}> {
  const res = await fetchPartnerDashboardService(
    "POST",
    {
      licenseId,
      startDate,
      endDate,
    },
    "general/getQualifiedLeads"
  )
  return res.qualifiedLeads as {
    total: number
    filter1s: Array<{
      name: string
      total: number
    }>
  }
}

/**
 * Fetches businesses with applications submitted for quotes and their counts by industry filter1.
 *
 * @param {string} licenseId - The ID of the license.
 * @param {string} startDate - The start date for the query.
 * @param {string} endDate - The end date for the query.
 * @returns {Promise<{total: number, filter1s: Array<{name: string, total: number}>}>}
 */
export async function getBusinessesWithApplicationsSubmittedForQuotes(
  licenseId: string,
  startDate: string,
  endDate: string
): Promise<{
  total: number
  filter1s: Array<{
    name: string
    total: number
  }>
}> {
  const res = await fetchPartnerDashboardService(
    "POST",
    {
      licenseId,
      startDate,
      endDate,
    },
    "general/getBusinessesWithApplicationsSubmittedForQuotes"
  )
  return res.businessesWithApplicationsSubmittedForQuotes as {
    total: number
    filter1s: Array<{
      name: string
      total: number
    }>
  }
}
