import { dev_log } from "../utilities"
import dayjs from "dayjs"

export interface StartEndDates {
  startDate: string
  endDate: string
}

export function getMonthDates(
  month: string,
  year: string
): StartEndDates | null {
  const monthNumber = getMonthNumber(month)
  if (
    monthNumber < 0 ||
    monthNumber > 11 ||
    isNaN(monthNumber) ||
    isNaN(Number(year))
  ) {
    return null // Return null for invalid month or year
  }

  dev_log("getMonthDates", {
    startDate: dayjs(`${year}-${monthNumber + 1}-01`).format("YYYY-MM-DD"),
    endDate: dayjs(`${year}-${monthNumber + 1}-01`)
      .endOf("month")
      .format("YYYY-MM-DD"),
  })

  return {
    startDate: dayjs(`${year}-${monthNumber + 1}-01`).format("YYYY-MM-DD"),
    endDate: dayjs(`${year}-${monthNumber + 1}-01`)
      .endOf("month")
      .format("YYYY-MM-DD"),
  }
}

function getMonthNumber(month: string): number {
  switch (month) {
    case "January":
      return 0
    case "February":
      return 1
    case "March":
      return 2
    case "April":
      return 3
    case "May":
      return 4
    case "June":
      return 5
    case "July":
      return 6
    case "August":
      return 7
    case "September":
      return 8
    case "October":
      return 9
    case "November":
      return 10
    case "December":
      return 11
    default:
      return -1
  }
}
