/* eslint-disable @typescript-eslint/no-explicit-any */
import { Card, AreaChart, Title, Text } from "@tremor/react"
import numeral from "numeral"

const valueFormatter = (number: number) => numeral(number).format("$0,0.00")

interface Props {
  title: string
  subtitle: string
  data: any
  categories: string[]
  index: string
  colors: string[]
}
export default function Chart({
  title,
  subtitle,
  data,
  categories,
  index,
}: Props) {
  return (
    <Card className="ring-0 mt-8 h-[28rem] bg-white">
      <Title>{title}</Title>
      <Text>{subtitle}</Text>
      <AreaChart
        style={{
          fontFamily: `ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
        }}
        className="mt-4"
        data={data}
        categories={categories}
        index={index}
        colors={["indigo", "fuchsia"]}
        valueFormatter={valueFormatter}
        yAxisWidth={70}
      />
    </Card>
    // <Card marginTop="mt-8">
    //   <Title>Performance</Title>
    //   <Text>Comparison between Sales and Profit</Text>
    //   <AreaChart
    //     marginTop="mt-4"
    //     data={data}
    //     categories={["Sales", "Profit"]}
    //     dataKey="Month"
    //     colors={["indigo", "fuchsia"]}
    //     valueFormatter={valueFormatter}
    //     height="h-80"
    //   />
    // </Card>
  )
}
