export const nerdwalletData = {
  "2024-04-01:2024-06-30": {
    totalPartnerRevShare: 2300,
    businessesWithAppsInProgress: 37,
    businessesWithAppsSubmittedForQuotes: 92,
  },
  "2024-05-01:2024-05-31": {
    totalPartnerRevShare: 850,
    businessesWithAppsInProgress: 13,
    businessesWithAppsSubmittedForQuotes: 34,
  },
  "2024-06-01:2024-06-30": {
    totalPartnerRevShare: 1450,
    businessesWithAppsInProgress: 24,
    businessesWithAppsSubmittedForQuotes: 58,
  },
  "2024-07-01:2024-09-30": {
    totalPartnerRevShare: 3525,
    businessesWithAppsInProgress: 63,
    businessesWithAppsSubmittedForQuotes: 141,
  },
  "2024-07-01:2024-07-31": {
    totalPartnerRevShare: 1375,
    businessesWithAppsInProgress: 18,
    businessesWithAppsSubmittedForQuotes: 55,
  },
  "2024-08-01:2024-08-31": {
    totalPartnerRevShare: 1075,
    businessesWithAppsInProgress: 22,
    businessesWithAppsSubmittedForQuotes: 43,
  },
  "2024-09-01:2024-09-30": {
    totalPartnerRevShare: 1075,
    businessesWithAppsInProgress: 23,
    businessesWithAppsSubmittedForQuotes: 43,
  },
}

export function getNerdwalletTotalPartnerRevShareForDateRange(
  startDate: string,
  endDate: string
): number | null {
  // console.log(
  //   "getNerdwalletTotalPartnerRevShareForDateRange",
  //   startDate,
  //   endDate
  // )
  try {
    return nerdwalletData[`${startDate}:${endDate}`].totalPartnerRevShare
  } catch (error) {
    return null
  }
}

export function getNerdwalletBusinessesWithAppsInProgressForDateRange(
  startDate: string,
  endDate: string
): number | null {
  // console.log(
  //   "getNerdwalletBusinessesWithAppsInProgressForDateRange",
  //   startDate,
  //   endDate
  // )
  try {
    return nerdwalletData[`${startDate}:${endDate}`]
      .businessesWithAppsInProgress
  } catch (error) {
    return null
  }
}

export function getNerdwalletBusinessesWithAppsSubmittedForQuotesForDateRange(
  startDate: string,
  endDate: string
): number | null {
  // console.log(
  //   "getNerdwalletBusinessesWithAppsSubmittedForQuotesForDateRange",
  //   startDate,
  //   endDate
  // )
  try {
    return nerdwalletData[`${startDate}:${endDate}`]
      .businessesWithAppsSubmittedForQuotes
  } catch (error) {
    return null
  }
}

export const hideRevenueChartForDateRanges = {
  "2024-04-01:2024-06-30": true,
  "2024-05-01:2024-05-31": true,
  "2024-06-01:2024-06-30": true,
  "2024-07-01:2024-09-30": true,
  "2024-07-01:2024-07-31": true,
  "2024-08-01:2024-08-31": true,
  "2024-09-01:2024-09-30": true,
}

export function shouldHideRevenueChartForDateRange(
  startDate: string,
  endDate: string
): boolean {
  return hideRevenueChartForDateRanges[`${startDate}:${endDate}`]
}
