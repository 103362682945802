import dayjs from "dayjs"

export interface StartEndDates {
  startDate: string
  endDate: string
}

export function getQuarterDates(
  quarter: string,
  year: string
): StartEndDates | null {
  // Validate quarter format
  if (!quarter.startsWith("Q") || isNaN(Number(quarter[1]))) {
    return null // Return null for invalid quarter format
  }

  const monthOffset = {
    Q1: 0,
    Q2: 3,
    Q3: 6,
    Q4: 9,
  }[quarter] as number

  const startDate = new Date(Number(year), monthOffset, 1).toISOString()
  const endDate = new Date(Number(year), monthOffset + 3, 0).toISOString() // Set end date to end of the month

  return {
    startDate: dayjs(startDate).format("YYYY-MM-DD"),
    endDate: dayjs(endDate).format("YYYY-MM-DD"),
  }
}
