import { Tooltip } from "./Tooltip/Tooltip"
import { QuestionMarkCircleIcon } from "@heroicons/react/solid"
import React from "react"

interface Props {
  tooltipText: string
  tooltipTitle: string
  setMobileTooltipOpen: (open: boolean) => void
  setMobileTooltipTitle: (title: string) => void
  setMobileTooltipBody: (body: string) => void
}

function TooltipComponent({
  tooltipText,
  tooltipTitle,
  setMobileTooltipOpen,
  setMobileTooltipTitle,
  setMobileTooltipBody,
}: Props) {
  return (
    <>
      <span className="ml-1 hidden sm:inline-block">
        <Tooltip content={tooltipText} />
      </span>
      {/* <InfoCircledIcon /> */}
      <QuestionMarkCircleIcon
        className="ml-2 inline-block sm:hidden text-gray-400 w-5 h-5"
        onClick={() => {
          setMobileTooltipOpen(true)
          setMobileTooltipTitle(tooltipTitle)
          setMobileTooltipBody(tooltipText)
        }}
      />
    </>
  )
}

export default TooltipComponent
